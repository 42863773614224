import React from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Title>404: Not Found</Title>
    <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
  </Layout>
)

const Title = styled.h1`
  color: rgb(197, 158, 93);
`
const Text = styled.p`
  color: rgb(197, 158, 93);
`

export default NotFoundPage
